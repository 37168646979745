import React from 'react';
import { H1, P } from '@dnb/eufemia';
import Head from '@dnb/helmet';
import CrashingComponent from '../../components/CrashingComponent';
import WorkingComponent from '../../components/WorkingComponent';

function PageIndex() {
  return (
    <>
      <Head>
        <title>Page Title</title>
      </Head>

      <H1 bottom="1rem" top="3rem">
        GlobalErrorBoundary
      </H1>
      <P bottom="2rem">
        This page demonstrates failing code in production and how you can use
        GlobalErrorBoundary to catch them.
      </P>

      <WorkingComponent />
      <CrashingComponent />
    </>
  );
}

export default PageIndex;
