import React from 'react';
import { InfoCard } from '@dnb/eufemia';
import { heart_medium as Icon } from '@dnb/eufemia/icons';

export default function WorkingComponent() {
  return (
    <InfoCard
      title="WorkingComponent"
      text="If you see this, the component rendered successfully"
      icon={Icon}
      bottom="1rem"
    />
  );
}
