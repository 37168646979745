import React from 'react';
import { InfoCard } from '@dnb/eufemia';
import { exclamation_triangle_medium as Icon } from '@dnb/eufemia/icons';

export default function CrashingComponent() {
  React.useEffect(() => {
    throw new Error('CrashingComponent is throwing an error!');
  }, []);

  return (
    <InfoCard
      title="CrashingComponent"
      text="If you see this, the component rendered successfully"
      icon={Icon}
    />
  );
}
